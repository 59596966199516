

































































import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'UOttawaLabExamPt1Fr_2024',
  components: {CalculationInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        equipment: [],
        unknownNumber: null,
        g: null,
        ml: null,
        gPerCm3: null,
      },
      items: [
        {label: '50 mL bécher', value: 'beaker_50ml'},
        {label: '150 mL bécher', value: 'beaker_150ml'},
        {label: '250 m bécher', value: 'beaker_250ml'},
        {label: '400 mL bécher', value: 'beaker_400ml'},
        {label: '600 mL bécher', value: 'beaker_600ml'},
        {label: '10 mL cylindre gradué', value: 'graduated_cylinder_10ml'},
        {label: '50 mL cylindre gradué', value: 'graduated_cylinder_50ml'},
        {label: '60 mL entonnoir', value: 'funnel_60ml'},
        {label: '125 mL fiole conique', value: 'erlenmeyer_flask'},
        {label: '75 mL verre de montre', value: 'watch_glass'},
        {label: '250 mL pissette', value: 'wash_bottle'},
        {label: 'Balance', value: 'balance'},
        {label: 'Microspatule', value: 'spatula'},
        {label: 'Noix de pince', value: 'clamp_holder'},
        {label: 'Papier à peser', value: 'weighing_paper'},
        {label: 'Pince à creuset', value: 'crucible_tongs'},
        {label: 'Pince à éprouvette', value: 'test_tube_holder'},
        {label: 'Pince extension', value: 'clamp_extension'},
        {label: 'Pince universelle (3 doigts)', value: 'clamp_universal'},
        {label: 'Spatule', value: 'scoopula'},
        {label: 'Support à éprouvette', value: 'test_tube_rack'},
        {label: 'Tige de teflon', value: 'teflon_rod'},
      ],
    };
  },
};
